<template>
    <div class="baseMain">
        <div class="baseClass">
            <div class="baseClassLeft">
                <div class="baseClassLeftIn">
                    <div class="baseClassLeftInTop">
                        <el-input style="width: 300px;" placeholder="请输入要搜索的内容"
                                  suffix-icon="el-icon-search" v-model="filterText"></el-input>
                    </div>
                    <div class="baseClassLeftInMain nui-scroll">
                        <el-tree ref="tree" :data="detailTreeData" node-key="id" :default-expand-all="false"
                                 :default-expanded-keys="defaultExpandIds" :expand-on-click-node="false"
                                 :filter-node-method="filterNode" :highlight-current="true" :props="defaultProps"
                                 @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse">
                            <!--@node-click="lookDetail" -->
                            <template #default="{node,data}">
                                <div class="custom-tree-node">
                                    <div class="custom-tree-node-son" @click="lookDetail(data)">
                                        <el-icon :size="18" v-if="data.id == 0">
                                            <HomeFilled/>
                                        </el-icon>
                                        <el-icon :size="18" color="#E6A23C" v-else-if="data.id == -1">
                                            <user-filled/>
                                        </el-icon>
                                        <el-icon :size="18" color="#409EFF" v-else>
                                            <user-filled/>
                                        </el-icon>
                                        <span style="margin-left: 4px;">{{ node.label }}</span>
                                    </div>
                                    <div v-if="data.id != -1">
                                        <el-dropdown trigger="click" @command="handleCommand"
                                                     v-if="(node.level != 5 && authorityStatus.organizationNew.show) || (node.level != 1 && authorityStatus.organizationDel.show) || (node.level != 1 && authorityStatus.organizationEdit.show)">
                                            <el-icon>
                                                <MoreFilled/>
                                            </el-icon>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                            v-if="node.level != 5 && authorityStatus.organizationNew.show"
                                                            :command="composeValue('add', node)">
                                                        <span>创建</span>
                                                        <span v-if="treeLevelObj[node.level+1]">{{treeLevelObj[node.level+1]['name']}}</span>
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                            v-if="node.level != 1 && authorityStatus.organizationDel.show"
                                                            :command="composeValue('del', node)">删除
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                            v-if="node.level != 1 && authorityStatus.organizationEdit.show"
                                                            :command="composeValue('edit', node)">编辑
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <!--<el-link type="primary">添加</el-link>-->
                                        <!--&nbsp;-->
                                        <!--<el-link type="danger">删除</el-link>-->
                                        <!--&nbsp;-->
                                        <!--<el-link type="primary">编辑</el-link>-->
                                    </div>
                                </div>
                            </template>
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="baseClassRight" v-if="treeLookMsg != null">
                <div class="baseSearch clearfloat">
                    <div class="baseSearchOne">
                        <div class="baseSearchOneLeft">
                            <span>姓名/学工号：</span>
                        </div>
                        <div class="baseSearchOneRight">
                            <el-input v-model="searchForm.key_words" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div class="baseSearchOne">
                        <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                        <el-button @click="cleanAll">清空</el-button>
                    </div>
                </div>
                <div class="baseOperate clearfloat">
                    <div class="baseOperateOne2">
                        <span class="baseOperateText">已选 <strong
                                style="color: rgba(65, 148, 242, 1);">{{this.tableDataSel.length}}</strong> 位用户</span>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="primary" @click="openAddStaff('new')" v-if="authorityStatus.studentNew.show">
                            添加学生
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="success" @click="openImport" v-if="authorityStatus.studentImport.show">批量导入
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button @click="openExport" v-if="authorityStatus.studentExport.show">导出全部</el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="warning" @click="openMoveStaff" v-if="authorityStatus.studentChange.show">
                            移动班级
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML" v-if="authorityStatus.studentDel.show">
                        <el-button type="danger" @click="batchDel">批量删除</el-button>
                    </div>
                </div>
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 :publicTableConfigure="tableConfigure" @selAllDataFun="tableMsgFromSon($event)"
                                 ref="publicTableUserStudent">
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="name" label="姓名"></el-table-column>
                        <el-table-column prop="orgs.name" label="班级"></el-table-column>
                        <el-table-column prop="phone" label="联系方式"></el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link class="tableButton" type="primary"
                                         @click.prevent="openAddStaff('edit',scope.row)"
                                         v-if="authorityStatus.studentEdit.show">
                                    编辑
                                </el-link>
                                <el-link class="tableButton" type="danger" @click.prevent="delStaff(scope.row)"
                                         v-if="authorityStatus.studentDel.show">
                                    删除
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
                <div class="basePage" style="margin-top: 4px;">
                    <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                                @pageChange="getTableData"></publicPage>
                </div>
            </div>
        </div>
        <el-dialog :title="departmentTitle" v-model="departmentDialog" width="600px" :close-on-click-modal="false">
            <el-form :model="addDepartmentForm" :rules="addDepartmentRules" ref="refAddDepartmentForm"
                     label-width="auto">
                <template v-if="treeSelMsg.showLevel == 2">
                    <el-form-item label="校区名称" prop="name">
                        <el-input v-model="addDepartmentForm.name"></el-input>
                    </el-form-item>
                </template>
                <template v-if="treeSelMsg.showLevel == 3">
                    <el-form-item label="学段名称" prop="name">
                        <el-input v-model="addDepartmentForm.name"></el-input>
                    </el-form-item>
                    <!--<el-form-item label="学段类型">-->
                    <!--<el-radio-group v-model="addDepartmentForm.resource">-->
                    <!--<el-radio label="1">标准</el-radio>-->
                    <!--<el-radio label="2">自定义</el-radio>-->
                    <!--</el-radio-group>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="选择学段" prop="name" v-if="addDepartmentForm.resource == 1">-->
                    <!--<el-select v-model="addDepartmentForm.value" placeholder="请选择">-->
                    <!--<el-option v-for="item in biaozhunxueduanList" :key="item.value" :label="item.label"-->
                    <!--:value="item.value"></el-option>-->
                    <!--</el-select>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="学段名称" prop="name" v-if="addDepartmentForm.resource == 2">-->
                    <!--<el-input v-model="addDepartmentForm.name"></el-input>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="年级数" prop="name">-->
                    <!--<el-input-number v-model="addDepartmentForm.num" controls-position="right"-->
                    <!--:min="1"></el-input-number>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="每个年级的班级数" prop="name">-->
                    <!--<el-input-number v-model="addDepartmentForm.num" controls-position="right"-->
                    <!--:min="1"></el-input-number>-->
                    <!--</el-form-item>-->
                </template>
                <template v-if="treeSelMsg.showLevel == 4">
                    <template v-if="treeSelMsg.sort == 'add'">
                        <el-form-item label="年级类型">
                            <el-radio-group v-model="addDepartmentForm.resource">
                                <el-radio label="1">标准</el-radio>
                                <el-radio label="2">自定义</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="选择年级" v-if="addDepartmentForm.resource == 1">
                            <el-select v-model="addDepartmentForm.value" placeholder="请选择">
                                <el-option v-for="item in biaozhunxueduanList" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级数" v-if="addDepartmentForm.resource == 1">
                            <el-input-number v-model="addDepartmentForm.num" controls-position="right"
                                             :min="1"></el-input-number>
                        </el-form-item>
                    </template>
                    <el-form-item label="年级名称" prop="name" v-if="addDepartmentForm.resource == 2">
                        <el-input v-model="addDepartmentForm.name"></el-input>
                    </el-form-item>
                </template>
                <template v-if="treeSelMsg.showLevel == 5">
                    <el-form-item label="班级名称" prop="name">
                        <el-input v-model="addDepartmentForm.name"></el-input>
                    </el-form-item>
                </template>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveTree">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600px" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" v-if="dialogVisible">
                <el-divider>基本信息</el-divider>
                <el-form-item label="学工号" prop="card_number">
                    <el-input v-if="dialogTitle == '添加学生'" v-model="ruleForm.card_number"></el-input>
                    <span v-else-if="dialogTitle == '编辑学生'">{{ruleForm.card_number}}</span>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="ruleForm.sex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="班级" prop="class_id">
                    <el-cascader v-model="ruleForm.class_id" :options="class_id_list" :props="props"></el-cascader>
                </el-form-item>
                <el-form-item label="联系方式" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-divider>证件信息</el-divider>
<!--                <el-form-item label="国籍">-->
<!--                    <el-select v-model="ruleForm.nationality" placeholder="请选择">-->
<!--                        <el-option v-for="item in nationalityList" :key="item.value" :label="item.label"-->
<!--                                   :value="item.value"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="证件类型">-->
<!--                    <el-select v-model="ruleForm.id_card_type" placeholder="请选择">-->
<!--                        <el-option v-for="item in id_card_type_list" :key="item.value" :label="item.label"-->
<!--                                   :value="item.value"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="身份证号" prop="id_card">
                    <el-input v-model="ruleForm.id_card"></el-input>
                </el-form-item>
                <el-divider>其他信息</el-divider>
                <el-form-item label="宿舍号">
                    <el-input v-model="ruleForm.dorm_num"></el-input>
                </el-form-item>
                <el-form-item label="家庭住址">
                    <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item label="民族">
                    <el-input v-model="ruleForm.nation"></el-input>
                </el-form-item>
                <el-form-item label="家长信息1">
                    <el-input v-model="ruleForm.parent_one_name" style="width: 80px;" placeholder="姓名"></el-input>
                    <el-input v-model="ruleForm.parent_one_phone" style="width: 200px;margin-left: 10px;" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="家长信息2">
                    <el-input v-model="ruleForm.parent_two_name" style="width: 80px;" placeholder="姓名"></el-input>
                    <el-input v-model="ruleForm.parent_two_phone" style="width: 200px;margin-left: 10px;" placeholder="手机号"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAddStaffSave">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer title="批量导入" v-model="importDialog" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">
                <el-form-item label="批量导入学生模板">
                    <a href="./files/批量导入学生模板.xlsx" class="downMuban"
                       style="float: left;" download="批量导入学生模板.xlsx">点击下载模板</a>
                </el-form-item>
                <el-form-item label="上传文件" prop="ruleUploadList">
                    <el-upload style="width: 400px;" ref="ruleUpload" drag
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="ruleHandleChange" :before-remove="ruleHandleChange"
                               :file-list="batchForm.ruleFileList"
                               :auto-upload="false" :on-exceed="ruleHandleExceed"
                               list-type="text" accept=".xlsx" :limit="batchForm.ruleFileLimit">
                        <el-icon :size="67" color="#c0c4cc" style="margin-top: 20px;">
                            <UploadFilled/>
                        </el-icon>
                        <div>将文件拖到此处，或 <strong style="color: #0d84ff;">点击上传</strong></div>
                        <template #tip>
                            <div class="el-upload__tip">请使用上一项下载的模板进行上传</div>
                        </template>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveImport">开始导入</el-button>
                </el-form-item>
            </el-form>
            <!--<el-divider>导入历史</el-divider>-->
            <!--<publicTable :publicTableOtherHeight="searchHeight" :publicTableData="exportTableData">-->
            <!--<el-table-column prop="date" label="操作管理员"></el-table-column>-->
            <!--<el-table-column prop="name" label="设置时间"></el-table-column>-->
            <!--<el-table-column prop="name" label="导入状态"></el-table-column>-->
            <!--<el-table-column prop="name" label="设置成功"></el-table-column>-->
            <!--<el-table-column prop="name" label="设置失败"></el-table-column>-->
            <!--<el-table-column prop="name" label="失败列表"></el-table-column>-->
            <!--</publicTable>-->
        </el-drawer>
        <el-dialog title="失败列表" v-model="importFailDialog" width="600px" :close-on-click-modal="false">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="importFailTableData">
                <el-table-column prop="date" label="操作管理员"></el-table-column>
                <el-table-column prop="name" label="设置时间" width="110"></el-table-column>
                <el-table-column prop="name" label="导入状态"></el-table-column>
                <el-table-column prop="name" label="设置成功"></el-table-column>
                <el-table-column prop="name" label="设置失败"></el-table-column>
                <el-table-column prop="name" label="失败列表"></el-table-column>
            </publicTable>
        </el-dialog>
        <el-dialog title="移动班级" v-model="movieDialog" width="600px" :close-on-click-modal="false">
            <el-tree ref="tree" :data="detailTreeData" node-key="id" default-expand-all
                     :expand-on-click-node="false" :highlight-current="true" :props="defaultProps">
                <template #default="{node,data}">
                    <div class="custom-tree-node">
                        <span>{{ data.name }}</span>
                        <el-link type="primary" @click="saveMoveStaff(node)">移动</el-link>
                    </div>
                </template>
            </el-tree>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {MoreFilled, HomeFilled, UserFilled, UploadFilled} from '@element-plus/icons'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import XLSX from 'xlsx'

    import {utilsUploadFile, utilsGetAuthority, utilsExportExcel, utilsNotificationJump} from '../../utils/utils.js'

    import Global from '../../Global.js'

    export default {
        name: "userStudent",
        components: {
            publicPage,
            publicTable,
            MoreFilled,
            HomeFilled,
            UserFilled,
            UploadFilled
        },
        data() {
            return {
                userInfo: null,
                type: '1',
                filterText: '',
                detailTreeData: null,
                defaultExpandIds: [0],
                defaultProps: {
                    children: 'son',
                    label: 'name',
                },
                departmentDialog: false,
                departmentTitle: '',
                treeForm: {
                    name: ''
                },
                treeFormRules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                treeLevelObj: {
                    1: {
                        name: '学校'
                    },
                    2: {
                        name: '校区'
                    },
                    3: {
                        name: '学段'
                    },
                    4: {
                        name: '年级'
                    },
                    5: {
                        name: '班级'
                    }
                },
                treeSelMsg: null,
                addDepartmentForm: {
                    name: '',
                    resource: '1',
                    value: '',
                    num: 1,
                },
                addDepartmentRules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                biaozhunxueduanList: [
                    {
                        value: '一年级',
                        label: '一年级',
                    },
                    {
                        value: '二年级',
                        label: '二年级',
                    },
                    {
                        value: '三年级',
                        label: '三年级',
                    },
                    {
                        value: '四年级',
                        label: '四年级',
                    },
                    {
                        value: '五年级',
                        label: '五年级',
                    },
                    {
                        value: '六年级',
                        label: '六年级',
                    },
                    {
                        value: '初一',
                        label: '初一',
                    },
                    {
                        value: '初二',
                        label: '初二',
                    },
                    {
                        value: '初三',
                        label: '初三',
                    },
                ],
                treeLookMsg: null,
                searchForm: {
                    key_words: '',
                },
                searchHeight: 300,
                tableConfigure: {
                    selection: true,
                },
                tableLoading: false,
                tableData: [],
                total: 0,
                tableDataSel: [],
                selMsg: null,
                dialogVisible: false,
                dialogTitle: '',
                class_id_list: [],
                nationalityList: [
                    {
                        value: '1',
                        label: '中国'
                    }
                ],
                id_card_type_list: [
                    {
                        value: 1,
                        label: '身份证'
                    }
                ],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                ruleForm: {
                    card_number: '',
                    name: '',
                    sex: '',
                    class_id: '',
                    phone: '',
                    // nationality: '',
                    // id_card_type: '',
                    id_card: '',
                    dorm_num: '',
                    address: '',
                    nation: '',
                    parent_one_name: '',
                    parent_one_phone: '',
                    parent_two_name: '',
                    parent_two_phone: '',
                },
                rules: {
                    card_number: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    sex:[
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    class_id: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    id_card: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                importDialog: false,
                batchForm: {
                    ruleFileList: [],
                    ruleFileLimit: 1,
                    ruleUploadList: [],
                },
                batchRules: {
                    ruleUploadList: [
                        {type: 'array', required: true, message: '该项不能为空', trigger: 'blur'}
                    ],
                },
                movieDialog: false,
                exportTableData: [],
                importFailDialog: false,
                importFailTableData: [],
                authorityStatus: {
                    studentNew: {
                        method: 'post',
                        url: '/admin/v1/student',
                        show: false,
                    },
                    studentEdit: {
                        method: 'put',
                        url: '/admin/v1/student/{user_id}',
                        show: false,
                    },
                    studentDel: {
                        method: 'delete',
                        url: '/admin/v1/student/{user_id}',
                        show: false,
                    },
                    studentImport: {
                        method: 'post',
                        url: '/admin/v1/student/import',
                        show: false,
                    },
                    studentChange: {
                        method: 'post',
                        url: '/admin/v1/student/change_class',
                        show: false,
                    },
                    studentExport: {
                        method: 'get',
                        url: '/admin/v1/student_export',
                        show: false,
                    },
                    organizationNew: {
                        method: 'post',
                        url: '/admin/v1/organization_type1',
                        show: false,
                    },
                    organizationEdit: {
                        method: 'put',
                        url: '/admin/v1/organization_type1',
                        show: false,
                    },
                    organizationDel: {
                        method: 'delete',
                        url: '/admin/v1/organization_type1',
                        show: false,
                    }
                }
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            this.userInfo = JSON.parse(localStorage.getItem(Global.GlobalLocalName2));
            utilsGetAuthority(this.authorityStatus)
            this.getTreeData();
        },
        methods: {
            batchDel() {
                if (this.tableDataSel.length > 0) {
                    this.$confirm('此操作将批量删除选中的人员, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        let newArray = [];
                        this.tableDataSel.forEach(item => {
                            newArray.push(item.id)
                        })
                        let url = '/admin/v1/student/' + newArray.join(',');
                        businessDel(url, {}).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }).catch(() => {

                    })
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请先选择要删除的人员',
                        type: 'warning'
                    });
                }
            },
            saveMoveStaff(val) {
                this.$confirm('此操作将所选人员移动到 ' + val.data.name + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let newArray = [];
                    this.tableDataSel.forEach(item => {
                        newArray.push(item.id)
                    })
                    let url = '/admin/v1/teacher/change_class';
                    let data = {
                        class_id: val.data.id,
                        user_ids: newArray.join(',')
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.movieDialog = false;
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            openMoveStaff() {
                if (this.tableDataSel.length > 0) {
                    this.movieDialog = true;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请先选择要移动的人员',
                        type: 'warning'
                    });
                }
            },
            openExport() {
                let url = '/admin/v1/student?is_export=1&type=' + this.type + '&org_id=' + this.treeLookMsg.id;
                if (this.searchForm.key_words) {
                    url = url + '&key_words=' + this.searchForm.key_words
                }
                utilsExportExcel(url)
            },
            exportExcel() {
                this.$confirm('此操作将导出数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            saveImport() {
                this.$refs.batchForm.validate((valid) => {
                    if (valid) {
                        utilsUploadFile(this.batchForm.ruleUploadList[0].raw, 'import').then(res => {
                            let url = '/admin/v1/student/import';
                            let data = {
                                file_id: res.file_id,
                                type: 1,
                            }
                            businessPost(url, data).then(res2 => {
                                if (res2.data.status == 200) {
                                    utilsNotificationJump(res2.data.message, '/systemImport?module=student')
                                    this.importDialog = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res2.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        })
                    }
                })
            },
            openImport() {
                this.batchForm.ruleFileList = [];
                this.batchForm.ruleFileLimit = 1;
                this.batchForm.ruleUploadList = [];
                this.importDialog = true;
            },
            ruleHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.batchForm.ruleFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            ruleHandleChange(file, fileList) {
                this.batchForm.ruleUploadList = fileList;
                // let reader = new FileReader();
                // reader.readAsBinaryString(file.raw);
                // reader.onload = evt => {
                //     let data = evt.target.result; // 读到的数据
                //     let wb = XLSX.read(data, {type: "binary"});
                //     let newArray = wb.Sheets.Sheet1;
                //     let noK = ['!margins', '!ref', 'A1', 'B1', 'C1', 'D1'];
                //     let searchList = [];
                //     for (let k in newArray) {
                //         if (noK.indexOf(k) == -1) {
                //             let kNameList = k.split('')
                //             let num = kNameList[1] - 2;
                //             if (searchList[num]) {
                //                 searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                //             }
                //             else {
                //                 searchList[num] = {};
                //                 searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                //             }
                //         }
                //     }
                //     this.batchForm.tableData = searchList;
                //     // let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                //     // console.log(outdata);
                // };
            },
            delStaff(val) {
                this.$confirm('此操作将删除该人员(' + val.name + '), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/student/' + val.id;
                    businessDel(url, {}).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveAddStaffSave() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (!(Global.GlobalShenfenzhengReg.test(this.ruleForm.id_card))) {
                            this.$message({
                                showClose: true,
                                message: '证件号码格式有误',
                                type: 'warning'
                            });
                            return
                        }
                        let url = '/admin/v1/student';
                        let data = {
                            type: this.type,
                            card_number: this.ruleForm.card_number,
                            name: this.ruleForm.name,
                            sex: this.ruleForm.sex,
                            class_id: this.ruleForm.class_id[this.ruleForm.class_id.length - 1],
                            phone: this.ruleForm.phone,
                            // nationality: this.ruleForm.nationality,
                            // id_card_type: this.ruleForm.id_card_type,
                            id_card: this.ruleForm.id_card,
                            dorm_num: this.ruleForm.dorm_num,
                            address: this.ruleForm.address,
                            nation: this.ruleForm.nation,
                        }
                        if (this.ruleForm.parent_one_name.replace(/^\s+|\s+$/g, "") != "" && this.ruleForm.parent_one_phone.replace(/^\s+|\s+$/g, "") != "") {
                            data.parent_one = this.ruleForm.parent_one_name + '-' + this.ruleForm.parent_one_phone;
                        }
                        if (this.ruleForm.parent_two_name.replace(/^\s+|\s+$/g, "") != "" && this.ruleForm.parent_two_phone.replace(/^\s+|\s+$/g, "") != "") {
                            data.parent_two = this.ruleForm.parent_two_name + '-' + this.ruleForm.parent_two_phone;
                        }
                        for (let i in data) {
                            if (data[i] == "") {
                                delete data[i];
                            }
                        }
                        if (this.dialogTitle == '添加学生') {
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.dialogTitle == '编辑学生') {
                            url = url + '/' + this.selMsg.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                    }
                })
            },
            openAddStaff(sort, val) {
                this.ruleForm.card_number = '';
                this.ruleForm.name = '';
                this.ruleForm.sex = '';
                this.ruleForm.class_id = '';
                this.ruleForm.phone = '';
                // this.ruleForm.nationality = '';
                // this.ruleForm.id_card_type = '';
                this.ruleForm.id_card = '';
                this.ruleForm.dorm_num = '';
                this.ruleForm.address = '';
                this.ruleForm.nation = '';
                this.ruleForm.parent_one_name = '';
                this.ruleForm.parent_one_phone = '';
                this.ruleForm.parent_two_name = '';
                this.ruleForm.parent_two_phone = '';
                if (sort == 'new') {
                    this.dialogTitle = '添加学生';
                    this.dialogVisible = true;
                }
                else if (sort == 'edit') {
                    this.selMsg = JSON.parse(JSON.stringify(val));
                    this.dialogTitle = '编辑学生';
                    this.ruleForm.card_number = this.selMsg.card_number;
                    this.ruleForm.name = this.selMsg.name;
                    this.ruleForm.sex = this.selMsg.sex + '';
                    this.ruleForm.class_id = this.selMsg.class_id;
                    this.ruleForm.phone = this.selMsg.phone;
                    // this.ruleForm.nationality = this.selMsg.nationality;
                    // this.ruleForm.id_card_type = this.selMsg.id_card_type;
                    this.ruleForm.id_card = this.selMsg.id_card;
                    this.ruleForm.dorm_num = this.selMsg.dorm_num;
                    this.ruleForm.address = this.selMsg.address;
                    this.ruleForm.nation = this.selMsg.nation;
                    if (this.selMsg.parent_one) {
                        let one = this.selMsg.parent_one.split('-')
                        this.ruleForm.parent_one_name = one[0];
                        this.ruleForm.parent_one_phone = one[1];
                    }
                    if (this.selMsg.parent_two) {
                        let one = this.selMsg.parent_two.split('-')
                        this.ruleForm.parent_two_name = one[0];
                        this.ruleForm.parent_two_phone = one[1];
                    }
                    this.dialogVisible = true;
                }
                this.$nextTick(() => {
                    let dom = document.querySelectorAll('.el-cascader');
                    for (let i = 0; i < dom.length; i++) {
                        dom[i].style.width = '100%';
                    }
                })
            },
            tableMsgFromSon(event) {
                this.tableDataSel = event;
            },
            cleanAll() {
                this.searchForm.key_words = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/student?type=' + this.type + '&org_id=' + this.treeLookMsg.id + '&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                if (this.searchForm.key_words) {
                    url = url + '&key_words=' + this.searchForm.key_words
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.user_list;
                    }
                })
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            composeValue(sort, node) {
                return {
                    'sort': sort,
                    'node': node
                }
            },
            getAllClass() {
                let url = '/admin/v1/organization/lowest';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.class_id_list = res.data.data;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            lookDetail(data) {
                this.treeLookMsg = JSON.parse(JSON.stringify(data));
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.baseSearch');
                    let baseOperateDom = document.querySelector('.baseOperate');
                    let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight + 30;
                    this.searchHeight = searchHeight;
                    this.cleanAll();
                })
            },
            saveTree() {
                this.$refs.refAddDepartmentForm.validate((valid) => {
                    if (valid) {
                        if (this.treeSelMsg.level == 1) {
                            let url = '/admin/v1/organization';
                            let data = {
                                name: this.addDepartmentForm.name,
                                type: this.type,
                                parent_id: this.treeSelMsg.id
                            }
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                    this.departmentDialog = false;
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.treeSelMsg.level == 2) {
                            if (this.treeSelMsg.sort == 'add') {
                                let url = '/admin/v1/organization';
                                let data = {
                                    name: this.addDepartmentForm.name,
                                    type: this.type,
                                    parent_id: this.treeSelMsg.id,
                                }
                                businessPost(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                            else if (this.treeSelMsg.sort == 'edit') {
                                let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                                let data = {
                                    name: this.addDepartmentForm.name,
                                }
                                businessPut(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                        }
                        else if (this.treeSelMsg.level == 3) {
                            if (this.treeSelMsg.sort == 'add') {
                                let url = '/admin/v1/organization';
                                let data = {
                                    type: this.type,
                                    parent_id: this.treeSelMsg.id,
                                }
                                if (this.addDepartmentForm.resource == 1) {
                                    url = '/admin/v1/organization/batch_grade';
                                    data.name = this.addDepartmentForm.value;
                                    data.grade_num = this.addDepartmentForm.num;
                                }
                                else if (this.addDepartmentForm.resource == 2) {
                                    data.name = this.addDepartmentForm.name;
                                }
                                businessPost(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                            else if (this.treeSelMsg.sort == 'edit') {
                                let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                                let data = {
                                    name: this.addDepartmentForm.name,
                                }
                                businessPut(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                        }
                        else if (this.treeSelMsg.level == 4) {
                            if (this.treeSelMsg.sort == 'add') {
                                let url = '/admin/v1/organization';
                                let data = {
                                    name: this.addDepartmentForm.name,
                                    type: this.type,
                                    parent_id: this.treeSelMsg.id,
                                }
                                businessPost(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                            else if (this.treeSelMsg.sort == 'edit') {
                                let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                                let data = {
                                    name: this.addDepartmentForm.name,
                                }
                                businessPut(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                        }
                        else if (this.treeSelMsg.level == 5) {
                            if (this.treeSelMsg.sort == 'edit') {
                                let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                                let data = {
                                    name: this.addDepartmentForm.name,
                                }
                                businessPut(url, data).then(res => {
                                    if (res.data.status == 200) {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'success'
                                        });
                                        this.getTreeData();
                                        this.departmentDialog = false;
                                    }
                                    else {
                                        this.$message({
                                            showClose: true,
                                            message: res.data.message,
                                            type: 'warning'
                                        });
                                    }
                                })
                            }
                        }
                    }
                })
            },
            handleCommand(command) {
                let a = JSON.parse(JSON.stringify(command.node.data));
                a.sort = command.sort;
                a.level = a.level * 1;
                this.treeSelMsg = a;
                console.log(this.treeSelMsg);
                if (this.treeSelMsg.level == 1) {
                    if (this.treeSelMsg.sort == 'add') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level + 1;
                        this.departmentTitle = '添加' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = '';
                        this.departmentDialog = true;
                    }
                }
                else if (this.treeSelMsg.level == 2) {
                    if (this.treeSelMsg.sort == 'add') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level + 1;
                        this.departmentTitle = '添加' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = '';
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'edit') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level;
                        this.departmentTitle = '编辑' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = this.treeSelMsg.name;
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'del') {
                        this.$confirm('删除后该部门成员将纳入“未分组”管理，是否确认删除该部门?', '提示', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                            businessDel(url, {}).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }).catch(() => {

                        })
                    }
                }
                else if (this.treeSelMsg.level == 3) {
                    if (this.treeSelMsg.sort == 'add') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level + 1;
                        this.departmentTitle = '添加' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.resource = '1';
                        this.addDepartmentForm.value = '一年级';
                        this.addDepartmentForm.num = 1;
                        this.addDepartmentForm.name = '';
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'edit') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level;
                        this.departmentTitle = '编辑' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = this.treeSelMsg.name;
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'del') {
                        this.$confirm('删除后部门信息将无法恢复，需要重新创建，是否确认删除?', '提示', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                            businessDel(url, {}).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }).catch(() => {

                        })
                    }
                }
                else if (this.treeSelMsg.level == 4) {
                    if (this.treeSelMsg.sort == 'add') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level + 1;
                        this.departmentTitle = '添加' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = '';
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'edit') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level;
                        this.departmentTitle = '编辑' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.resource = 2;
                        this.addDepartmentForm.name = this.treeSelMsg.name;
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'del') {
                        this.$confirm('删除后部门信息将无法恢复，需要重新创建，是否确认删除?', '提示', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                            businessDel(url, {}).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }).catch(() => {

                        })
                    }
                }
                else if (this.treeSelMsg.level == 5) {
                    if (this.treeSelMsg.sort == 'edit') {
                        this.treeSelMsg.showLevel = this.treeSelMsg.level;
                        this.departmentTitle = '编辑' + this.treeLevelObj[this.treeSelMsg.showLevel].name;
                        this.addDepartmentForm.name = this.treeSelMsg.name;
                        this.departmentDialog = true;
                    }
                    else if (this.treeSelMsg.sort == 'del') {
                        this.$confirm('删除后部门信息将无法恢复，需要重新创建，是否确认删除?', '提示', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            let url = '/admin/v1/organization/' + this.treeSelMsg.id;
                            businessDel(url, {}).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }).catch(() => {

                        })
                    }
                }

                // if (command.sort == 'add') {
                //     let level = this.treeSelMsg.node.level + 1;
                //     this.departmentTitle = '添加' + this.treeLevelObj[level].name;
                //     this.addDepartmentForm.name = '';
                //     this.departmentDialog = true;
                // }
                // if (command.sort == 'del') {
                //     this.$confirm('删除后部门信息将无法恢复，需要重新创建，是否确认删除?', '提示', {
                //         confirmButtonText: '确认',
                //         cancelButtonText: '取消',
                //         type: 'warning',
                //     }).then(() => {
                //
                //     }).catch(() => {
                //     })
                // }
                // if (command.sort == 'edit') {
                //     let level = this.treeSelMsg.node.level;
                //     this.departmentTitle = '编辑' + this.treeLevelObj[level].name
                //     this.departmentDialog = true;
                // }
            },
            handleNodeExpand(data) {
                let flag = false
                this.defaultExpandIds.some(item => {
                    if (item === data.id) {
                        flag = true
                        return true
                    }
                })
                if (!flag) {
                    this.defaultExpandIds.push(data.id)
                }
            },
            handleNodeCollapse(data) {
                this.defaultExpandIds.some((item, i) => {
                    if (item === data.id) {
                        this.defaultExpandIds.splice(i, 1)
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=' + this.type;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let detailTreeData = JSON.parse(JSON.stringify(Global.GlobalTree));
                        detailTreeData[0].name = this.userInfo.school_name;
                        if (res.data.data.length > 0) {
                            detailTreeData[0].son = res.data.data
                        }
                        this.detailTreeData = detailTreeData;
                        this.class_id_list = res.data.data;
                        this.lookDetail(this.detailTreeData[0])
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .userTeacherML {
        margin-left: 16px;
    }

</style>
